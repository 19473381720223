//Utils
import { isJavascriptEnabled } from './utils/isJavascriptEnabled';
import { getFocusable } from './utils/getFocusable';
import { nextFocusable } from './utils/nextFocusable';
import { isPrintableChar } from './utils/isPrintableChar';
import { isInputable } from './utils/isInputable';
import { isSet } from './utils/isSet';

//Funtions
import { initEmblaCarousels } from "./functions/_emblaCarouselInit";

//Classes
import { Accordion } from "./classes/Accordion";
import { Dropdown } from "./classes/Dropdown";
import { StickyHeader } from "./classes/StickyHeader";
import { TabList } from "./classes/Tablist";
import { Layer } from "./classes/Layer";
import { SoftwareDownloads } from "./classes/SoftwareDownloads";


isJavascriptEnabled();


const focusableElements = [
    'a[href]:not([disabled]):not([hidden]):not([aria-hidden])',
    'area[href]:not([disabled]):not([hidden]):not([aria-hidden])',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe:not([disabled]):not([hidden]):not([aria-hidden])',
    'object:not([disabled]):not([hidden]):not([aria-hidden])',
    'embed:not([disabled]):not([hidden]):not([aria-hidden])',
    '[contenteditable]:not([disabled]):not([hidden]):not([aria-hidden])',
    '[tabindex]'
];
window.focusableElements = focusableElements;

const store = {
    activeLayers: [],
    activeLayer: ''
};
window.store = store;


/**
 * STICKY HEADER
 */
/** @type {HTMLElement[]} */
const masthead = document.querySelector("#masthead .site-header__inner");
if(isSet(masthead)){
    new StickyHeader(masthead);
}


/**
 * CAROUSELS
 */
initEmblaCarousels();


/**
 * ACCORDIONS
 */
    // Accordion Block
    const accordions = document.querySelectorAll(".block-accordion .accordion--js");
    accordions.forEach( function (accordionElement) {
        new Accordion(
            accordionElement,
            {
                type: "single",
                collapsible: true
            }
        );
    });

    // Mobile Menu
    const mobileMenu = document.querySelector(".site-menu--mobile.accordion--js");
    if(isSet(mobileMenu)){
        const mobileMenuAccordionItems = mobileMenu.querySelectorAll('[accordion-item]');

        mobileMenuAccordionItems.forEach( function (mobileMenuAccordionItem) {
            const trigger = mobileMenuAccordionItem.querySelector('[aria-controls]');
            const triggerId = trigger.id;
            const panelId = trigger.getAttribute('aria-controls');
    
            const panel = mobileMenuAccordionItem.querySelector('[aria-labelledby]');
            panel.id = panelId;
            panel.setAttribute('aria-labelledby', triggerId);
        });
    
        new Accordion(
            mobileMenu,
            {
                type: "single",
                collapsible: true
            }
        );
    }

/**
 * DROPDOWNS
 */
    // Jump-to menu dropdowns
    const jumpToNavs = document.querySelectorAll(".content-nav.jump-to");
    jumpToNavs.forEach(function (jumpToNavElm) {
        new Dropdown(
            jumpToNavElm, 
            {
                closeOnItemClick: true,
                awaitOpenAnimation: true,
                awaitCloseAnimation: true
            }
        );
    });

    //Filtering Dropdowns
    const filterNavs = document.querySelectorAll(".content-nav.filter");
    filterNavs.forEach(function (filterNavElm) {
        new Dropdown(
            filterNavElm, 
            {
                onBeforeClose: function(dropdown, event){
                    if (dropdown.dropdownItems.includes(event.target)) {
                        let input = dropdown.root.querySelector('input');
                        let value = event.currentTarget.value;
        
                        input.value = value;
                        input.dispatchEvent(new Event("change"));
                    }
                },
                closeOnItemClick: true,
                updateOnItemClick: true,
                awaitOpenAnimation: true,
                awaitCloseAnimation: true
            }
        );
    });


    //Destop Menu Dropdowns
    const navigationSubMenus = document.querySelectorAll(".sub-menu--js");
    const navigationSubMenuAPIs = [];
    navigationSubMenus.forEach(function (navigationSubMenu) {
        const dataId = navigationSubMenu.dataset.id;
        const dropdown = navigationSubMenu.querySelector('[aria-labelledby]');
        dropdown.setAttribute('aria-labelledby', 'menu-link--' + dataId);
        dropdown.id = 'sub-menu--' + dataId;

        const navigationSubMenuAPI = new Dropdown(
            navigationSubMenu,
            {
                onBeforeOpen: () => {
                    navigationSubMenuAPIs.forEach((api) => {
                        if(api === navigationSubMenuAPI) return;                    
                        api.closeDropdown();
                    })
                },
                openOnTriggerClick: false,
                openOnTriggerHover: true,
                awaitOpenAnimation: true,
                awaitCloseAnimation: true
            }
        );

        navigationSubMenuAPIs.push(navigationSubMenuAPI);
    });


/**
 * TABLISTS
 */
    const tabbedContent = document.querySelectorAll(".tabbed-content--js");
    tabbedContent.forEach( function (tabbedContentElement) {
        const tabPanels = tabbedContentElement.querySelectorAll('[role="tabpanel"]');
        const tabList = tabbedContentElement.querySelector('[role="tablist"]');

        tabPanels.forEach( function (tabPanel) {
            const tabID = tabPanel.getAttribute('aria-labelledby');
            const panelID = tabPanel.id;

            const tab = document.createElement('button');
            tab.id = tabID;
            tab.type = "button";
            tab.role = "tab";
            tab.setAttribute("aria-selected", "false");
            tab.setAttribute("aria-controls", panelID);
            tab.textContent = tabPanel.dataset.title;

            tabList.append(tab);
        });

        new TabList(
            tabbedContentElement,
            {
                orientation: 'vertical',
                convertToAccordion: true,
                accordionBreakpoint: '980'
            }
        );
    });


    const quadrantLinks = document.querySelectorAll(".quadrant-links--js");
    quadrantLinks.forEach( function (quadrantLinkBlock) {
        const tabpanels = quadrantLinkBlock.querySelectorAll('[role=tabpanel]');
        const tabs = quadrantLinkBlock.querySelectorAll('[role=tab]');

        tabpanels.forEach( function (tabpanel, i){
            const title = tabpanel.dataset.title;
            const subtitle = tabpanel.dataset.subtitle;
            const tabid = tabpanel.getAttribute('aria-labelledby');
            const panelid = tabpanel.id;

            const tab = tabs[i];
            tab.id = tabid;
            tab.setAttribute('aria-controls', panelid);

            const tabTitle = tab.querySelector('.quadrant__title');
            tabTitle.textContent = title;

            const tabContent = tab.querySelector('.quadrant__content');
            tabContent.textContent = subtitle;
        });

        new TabList(
            quadrantLinkBlock,
            {}
        );
    });


/**
 * LAYERS
 */
const layers = document.querySelectorAll('.layer--js');
layers.forEach( function (layerElement) {
    new Layer(layerElement, {
        awaitCloseAnimation: true,
        awaitOpenAnimation: true,
    });
});



function onHTMXswap(event){
    if(!event.target.classList.contains('query-grid')) return;

    let selector = event.detail.requestConfig.parameters.block_anchor;
    let termId = event.detail.requestConfig.parameters.filter_term;
    let search = event.detail.requestConfig.parameters.text_search;

    if(typeof selector === "undefined" || selector === null){
        selector = "";
    };

    if(typeof termId === "undefined" || termId === null){
        termId = "all";
    }

    if(typeof search === "undefined" || search === null){
        search = "";
    }

    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set("selector", selector);
    queryParams.set("termid", termId);
    queryParams.set("text", search);
    history.replaceState(null, null, "?"+queryParams.toString());
}
document.addEventListener("htmx:afterSwap", (e) => onHTMXswap(e));


const searchAgainElms = document.querySelectorAll('.search-again');
searchAgainElms.forEach(function (searchAgainElm) {
    const dataId = searchAgainElm.dataset.id;

    const searchAgainPanel = document.querySelector('.search-expandable[data-id="' + dataId + '"]');
    const searchAgainPanelInner = searchAgainPanel.querySelector('.filter--search');
    const searchAgainPanelHeight = searchAgainPanelInner.offsetHeight + 'px';
    searchAgainPanel.style.setProperty('--content-height', searchAgainPanelHeight);

    searchAgainElm.addEventListener("click" , function(){
        searchAgainElm.classList.toggle('toggled');
        searchAgainPanel.classList.toggle('toggled');
    } , false);
});


const softwareDownloadsElement = document.querySelector('.software-downloads--js');
if(isSet(softwareDownloadsElement)){
    new SoftwareDownloads(softwareDownloadsElement);
}


const searchResultTabsElement = document.querySelector('.search-results__results .tab-bar--js');
if(isSet(searchResultTabsElement)){
    new TabList(
        searchResultTabsElement,
        {}
    );
}

const searchAgainElement = document.querySelector('.search-results__header.accordion--js');
if(isSet(searchAgainElement)){
    new Accordion(
        searchAgainElement,
        {
            type: "single",
            collapsible: true
        }
    );
}









/***************************************
 * --- LEGACY OLD THEME - REQUIRES PASS OVER AND UPDATING
 **************************************/
window.addEventListener('DOMContentLoaded', (event) => {
    let slideshare_embeds = document.querySelectorAll('.wp-block-embed-slideshare');

    if (typeof(slideshare_embeds) != 'undefined' && slideshare_embeds != null) {
        for (let i = 0, l = slideshare_embeds.length; i < l; i++) {
            let iframe_elm = slideshare_embeds[i].querySelector('iframe');
            iframe_elm.setAttribute('width' , '595');
            iframe_elm.setAttribute('height' , '485');
        }
    }
});

window.addEventListener('DOMContentLoaded', (event) => {
    const download_buttons = document.querySelectorAll('.wp-block-button.download');
    if (typeof(download_buttons) != 'undefined' && download_buttons != null) {
        for (let i = 0, l = download_buttons.length; i < l; i++) {
            download_buttons[i].addEventListener('click', convertDownloadable, false);
        }
    }
});

const convertDownloadable = (e) => {
    let buttonOuter = e.currentTarget;
    let buttonInnerLink = buttonOuter.querySelector('.wp-block-button__link');
    buttonInnerLink.setAttribute('download' , '');
}

function insertCookieEmbedWarning(optoutContainer){
    let inlineWarning = `
        <div class="cookieconsent-optout-marketing cookieconsent-optout-statistics alignwide">
            <div class="block-spss-inline-cta has-teal-background-color is-style-outlined">
                <div class="content-col">
                    <div class="inline-cta-content">
                        <h2 class="has-dark-color has-xl-font-size has-text-color">
                            Sorry, third party embedded content was unable to be loaded due to cookie preferences.
                        </h2>
                        <p class="has-dark-color has-text-color">
                            This is due to the third parties (e.g. YouTube, Slideshare) requiring marketing & statistics cookies in order to display their content.
                        </p>
                    </div>
                </div>
                <div class="cta-col is-vertical is-layout-flex wp-block-buttons items-center">
                    <div class="wp-block-button is-style-filled">
                        <a class="has-white-color has-dark-background-color wp-block-button__link has-text-color has-background wp-element-button" href="javascript: Cookiebot.renew()"><strong>Change Cookie Consent</strong></a>
                    </div>
                </div>
            </div>
        </div>
    `;
    optoutContainer.classList.add('cookieconsent-optin-marketing' , 'cookieconsent-optin-statistics');
    optoutContainer.insertAdjacentHTML('afterend', inlineWarning);
}


window.addEventListener('load', function () {
    let embedWrappers = document.querySelectorAll('.wp-block-embed__wrapper');
    for (let i = 0; i < embedWrappers.length; i++) {
        insertCookieEmbedWarning(embedWrappers[i]);
    }
});

window.addEventListener('CookiebotOnLoad', function () {
    let youtubeWrappers = document.querySelectorAll('.wp-block-embed-youtube .wp-block-embed__wrapper');

    for (let i = 0; i < youtubeWrappers.length; i++) {
        if(Cookiebot.consent.statistics == true && Cookiebot.consent.marketing == true){
            youtubeWrappers[i].style.display = 'block';
        }else{
            youtubeWrappers[i].style.display = 'none';
        }
    }
});


function subscribeSPSS(){
    if(
        CookieConsent.consent.necessary && 
        CookieConsent.consent.statistics && 
        CookieConsent.consent.marketing 
    ){
        const hasUserViewed = localStorage.getItem("viewedSubscribeModal");

        if(hasUserViewed != null && hasUserViewed === "true") return;

        MicroModal.show('subscribe-spss');

        localStorage.setItem("viewedSubscribeModal", "true");
    }
}

window.addEventListener('CookiebotOnConsentReady', function(){
    subscribeSPSS();
}, false);

window.addEventListener('CookiebotOnAccept', function(){
    subscribeSPSS();
}, false);

function CookiebotCallback_OnAccept() {
    subscribeSPSS();
}